body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Playfair+Display:ital,wght@0,600;1,500&family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Oswald&family=Playfair+Display:ital,wght@0,600;1,500&family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Oswald&family=Playfair+Display:ital,wght@0,600;1,500&family=Poppins:wght@500&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


.balloons img {
  height: 100px;
  position: absolute;
}
.balloons img:nth-child(1) {
  animation: move 10s 5s linear infinite;
}
.balloons img:nth-child(2) {
  left: 100px;
  animation: move 15s 8s linear infinite;
}
.balloons img:nth-child(3) {
  left: 200px;
  animation: move 18s 6s linear infinite;
}
.balloons img:nth-child(4) {
  left: 300px;
  animation: move 20s 4s linear infinite;
}
.balloons img:nth-child(5) {
  left: 400px;
  animation: move 12s 10s linear infinite;
}
.balloons img:nth-child(6) {
  left: 500px;
  animation: move 21s 8s linear infinite;
}
.balloons img:nth-child(7) {
  left: 600px;
  animation: move 10s 6s linear infinite;
}
.balloons img:nth-child(8) {
  left: 700px;
  animation: move 10s 5s linear infinite;
}
.balloons img:nth-child(9) {
  left: 800px;
  animation: move 17s 6s linear infinite;
}
.balloons img:nth-child(10) {
  left: 200px;
  animation: move 10s 5s linear infinite;
}
.balloons img:nth-child(11) {
  left: 100px;
  animation: move 16s 5s linear infinite;
}
.balloons img:nth-child(12) {
  left: 900px;
  animation: move 18s 5s linear infinite;
}
.balloons img:nth-child(13) {
  left: 100px;
  animation: move 12s 5s linear infinite;
}
.balloons img:nth-child(14) {
  left: 100px;
  animation: move 17s 6s linear infinite;
}
.balloons img:nth-child(15) {
  left: 800px;
  animation: move 16s 2s linear infinite;
}
.balloons img:nth-child(16) {
  left: 100px;
  animation: move 16s 2s linear infinite;
}

@keyframes move {
  0% {
    top: 700px;
    transform: rotate(45deg);
  }
  25% {
    opacity: 0;
    top: -200px;
    transform: translateX(10px) rotate(90deg);
  }
  50% {
    top: 350px;
    transform: translateX(30px) rotate(180deg);
  }
  75% {
    top: 500px;
    transform: translateX(20px) scale(1) rotate(270deg);
  }
  100% {
    top: 600px;
    transform: rotate(360deg);
  }
}
